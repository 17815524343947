import logo from './logo.svg';
import './App.css';
import pccm from './assets/pccm.jpg';
import pccmLogo from './assets/pccm_logo.png';
import loading from './assets/loading.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={pccmLogo} className="logo" alt="logo" />
        <img src={loading} className="App-logo" alt="logo" />
        <p>
          The PCCM website is coming soon...
        </p>
      </header>
    </div>
  );
}

export default App;
